import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogPostGeneralDentist: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const general_dentist_img = data.general_dentist_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>General Dentist & the Services they Provide - The Dentists At Gateway Crossing</title>
      <meta name="description" content="General dentists are qualified to provide a wide range of dental services for patients. Check-ups, fillings, crowns, bridges, and other dental implants are all examples of general dentistry." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="General dentists are qualified to provide a wide range of dental services for patients. Check-ups, fillings, crowns, bridges, and other dental implants are all examples of general dentistry." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="General dentists are qualified to provide a wide range of dental services for patients. Check-ups, fillings, crowns, bridges, and other dental implants are all examples of general dentistry." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                General Dentists and The Services They Provide
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2021/09/06
                </li>
                <li className="list-inline-item badge badge-primary">
                  General Dentist
                </li>
                <li className="list-inline-item badge badge-secondary">
                  McCordsville Dentist
                </li>
              </ul>
                <Img fluid={general_dentist_img} className="blog-post-img"/>
              {/*<h2 className="mb-3">
                Are dental implants worth it?
              </h2>*/}
              <p>
              General dentists are qualified to provide a wide range of dental services for patients. Check-ups, fillings, crowns, bridges, and other dental implants are all examples of general dentistry. In addition to offering basic dental services, including fillings and extractions, this plan also covers gum disease treatment as well as dental cleanings for youngsters. It also includes preventative treatments such as fluoride and x-rays.              </p>
              <p>
              A general dentist's responsibilities include aesthetic, preventative, and restorative care for the whole field of dental health. This blog post discusses a variety of dental treatments that may be found at the practice, but it isn't limited to:
              </p>
              <p>
              -Checkups: Dental checkups can include a comprehensive examination of your teeth and gums, as well as dental x-rays. Check-ups can help your dentist determine whether you have any problems that need to be addressed, such as cavities, gum disease, and more. A dental check-up is crucial to maintaining healthy oral care, which can help avoid problems in the future. 
              </p>
              <p>
              -Cleanings: Cleanings are an important element of a comprehensive dental care plan. They eliminate plaque and tartar buildup on teeth, which can result in gum disease if left unchecked.
              </p>
              <p>
              -Dental Fillings: A filling is used to repair or strengthen a tooth that has been damaged by decay or fractures. Fillings are often made of porcelain, composite resin, gold alloys, or amalgam (a mercury alloy). 
              </p>
              <p>
              -Dental Crowns: Crowns are a fantastic method for any general dentist to preserve teeth from harm and allow patients to retain their natural teeth.
              </p>
              <p>
              -Veneers:  Veneers are a dental service that is commonly used to improve the look of one's teeth. They're a fantastic option for people who want to improve the aesthetics of their natural teeth. 
              </p>
              <p>
              -Dentures: Denture replacement is the process of replacing missing teeth and surrounding tissue with porcelain prosthetic teeth, allowing you to use your mouth again. Dentures are generally removable, though dentures may also be placed in the mouth permanently with implants.
              </p>
              <p>
              -Implants: Dental implants are typically made of titanium and are placed in the jawbone. They're utilized to help with both fixed and removable dental prosthetic installations.
              </p>
              <p>
              -Root Canal: Root canal therapy is a type of dental treatment that aims to save the natural tooth by cleaning, disinfecting, shaping, and filling an infected root canal system. 
              </p>
              <p>
              -Emergency Dentist: If you have a knocked-out tooth, an abscess, or any other type of dental emergency, it's critical to get the issue addressed right away. If your emergency occurs outside of normal office hours, you may need to find a dentist that specializes in seeing emergency patients after hours. 
              </p>
              <p>
              -Bridges: The placement of two artificial teeth that bridge the gap created by one or more missing teeth is known as a dental bridge. They may be permanent or removable as needed.
              </p>
              <p>
              -Teeth Whitening: Teeth whitening is a non-invasive cosmetic dental treatment that may be done in the dentist's office. Depending on what you're aiming to achieve and how much time or money you have, there are a variety of techniques to whiten your teeth.
              </p>
              <p>
              -Wisdom Teeth Removal: The last (or third) molars are known as wisdom teeth, which most individuals have at the back of their mouth. The average age of a person when their wisdom teeth come in is 17 to 26 years, but they can arise earlier or later than this.
              </p>
              <p>
              Dental hygiene and early detection are critical for preserving your teeth. A dental practice that accepts most dental insurance plans and offers alternate payment choices such as cash and credit card is a good place to start.
              </p>
              <p>
              On your first dental visit, you should try to arrive early. By staying on top of the documentation, you'll be able to finish any necessary paperwork and registration with your dentist before being seen.
              </p>
              <p>
              If you'd like to book an appointment with a McCordsville Dentist, call us and schedule your appointment!
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default BlogPostGeneralDentist

export const query = graphql`
  query BlogPostGeneralDentistQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    general_dentist_img: file(name: { eq: "general-dentists-services-provided" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
